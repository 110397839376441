<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.agencySearch')"
    width="800"
    max-width="800"
    :color="'next'"
  >
    <v-container class="pt-0 px-8">
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <v-col>
            <v-chip
              ripple
              class="font-weight-black float-right"
              @click="onClickClearButton()"
              >{{ $t('button.clear') }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyCode"
              :label="$t('header.agencyMasterTable.agencyCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyName"
              :label="$t('header.agencyMasterTable.agencyName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesCode"
              :label="$t('header.salesMasterTable.salesCode')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col>
          <v-btn
            class="next float-right"
            width="33%"
            dark
            @click="onClickSearchButton()"
          >
            {{ $t('button.search') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-actions>
              <AgencySearchList
                :records="agencyRecords"
                :checkboxes="agencyCheckboxes"
                :subheader1="$t('label.subheader.agencyCode')"
                :subheader2="$t('label.subheader.agencyName')"
                :subheader3="$t('label.subheader.salesCode')"
              >
              </AgencySearchList>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn width="33%" class="next" dark @click="onClickRegisterButton()">
        {{ $t('button.register') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAgencyIdList } from '@/apis/agency/agencies';
import { MaxSearchResult } from '@/lib/const';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import AgencySearchList from '@/components/organisms/agency/AgencySearchList';

export default {
  components: { Dialog, ErrorDialog, AgencySearchList },
  data() {
    return {
      // 代理店検索項目
      condition: {
        agencyCode: '',
        salesCode: '',
        agencyName: '',
      },

      // 代理店データ
      agencyRecords: [],

      // 代理店選択データ
      agencyCheckboxes: [],

      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0'],
    };
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    open() {
      this.$refs.dialog.open();
      this.$set(this, 'agencyRecords', []);
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      this.condition.agencyCode = '';
      this.condition.salesCode = '';
      this.condition.agencyName = '';
    },

    // 代理店一覧の取得
    async fetchAgencies(condition) {
      // 代理店一覧の取得
      const agencies = await getAgencyIdList(condition || {}).catch(() => {});

      // 代理店一覧が取得できなかった場合、以降の処理を中止する
      if (!agencies) return;

      // 最大検索件数を超える場合
      if (agencies.length > MaxSearchResult.AgencyConnection) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencySearchError'),
          this.$t('error.invalidMaxSearchResult', {
            maxSearchResult: MaxSearchResult.AgencyConnection,
          })
        );
        return;
      }

      // 代理店選択状態の指定
      const agencyCheckboxes = agencies.map(agency => ({
        ...agency,
        selected: false,
      }));

      // 代理店一覧表示の更新
      this.$set(this, 'agencyCheckboxes', agencyCheckboxes);
      this.$set(this, 'agencyRecords', agencies);
    },

    // クリアボタンの押下時
    onClickClearButton() {
      this.init();
      this.$emit('onClear');
    },

    // 検索ボタンの押下時
    async onClickSearchButton() {
      // 検索項目
      const condition = {
        ...(this.condition.agencyCode && {
          agencyCode: this.condition.agencyCode,
        }),
        ...(this.condition.salesCode && {
          salesCode: this.condition.salesCode,
        }),
        ...(this.condition.agencyName && {
          agencyName: this.condition.agencyName,
        }),
      };
      await this.fetchAgencies(condition);
    },

    // 登録ボタンの押下時
    async onClickRegisterButton() {
      const agencies = this.agencyCheckboxes.filter(agency => agency.selected);
      // 代理店が選択されていない場合
      if (agencies.length < 1) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyConnectionUpdateConfirm'),
          this.$t('error.invalidAgencySelect')
        );
        return;
      }

      this.$emit('onSuccess', agencies);
    },
  },
};
</script>
