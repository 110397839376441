var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.$t("title.agency.agencyConnectionUpdateConfirm"),
        width: "600",
        "max-width": "600",
        color: "next"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.onClickUpdateButton()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.update")) + "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { margin: "0 auto" }
                      },
                      [
                        _vm.isRegister
                          ? _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "description.confirmRegisterAgencyConnection"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "description.confirmDeleteAgencyConnection"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-actions",
                            [
                              _c("AgencySearchList", {
                                attrs: {
                                  records: _vm.agencies,
                                  subheader1: _vm.$t(
                                    "label.subheader.agencyCode"
                                  ),
                                  subheader2: _vm.$t(
                                    "label.subheader.agencyName"
                                  ),
                                  subheader3: _vm.$t(
                                    "label.subheader.salesCode"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }