<template>
  <v-main class="px-0 py-0">
    <v-list-item style="height: 16px;" v-if="checkboxes">
      <v-list-item-action>
        <v-checkbox v-model="isSelectedAll" @change="onChangeSelectAll()">
        </v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        {{ $t('checkbox.selectAll') }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="my-0 py-0">
      <v-list-item-action v-if="checkboxes" class="my-0 py-0">
      </v-list-item-action>
      <v-list-item-content class="my-0 py-0">
        <v-row class="my-0 py-0" style="height:36">
          <v-col cols="3" class="my-0 py-0">
            <p class="mb-0 mt-2 py-0">
              {{ subheader1 }}
            </p>
          </v-col>
          <v-col cols="6" v-if="subheader2" class="my-0 py-0">
            <p class="mb-0 mt-2 py-0">
              {{ subheader2 }}
            </p>
          </v-col>
          <v-col cols="3" v-if="subheader3" class="my-0 py-0">
            <p class="mb-0 mt-2 py-0">
              {{ subheader3 }}
            </p>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-virtual-scroll :items="records" height="360px" item-height="48px">
      <template v-slot:default="{ item }">
        <v-divider></v-divider>
        <v-list-item dense :key="item.id" style="height:48px">
          <v-list-item-action v-if="checkboxes">
            <v-checkbox
              dense
              hide-details
              v-model="
                checkboxes.find(checkbox => checkbox.id === item.id).selected
              "
            >
            </v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-row style="height:48px">
              <v-col cols="3">
                <p style="font-size:14px">
                  {{ item.agencyCode }}
                </p>
              </v-col>
              <v-col cols="6" v-if="subheader2">
                <p style="font-size:14px">
                  {{ item.agencyName }}
                </p>
              </v-col>
              <v-col cols="3" v-if="subheader3">
                <p style="font-size:14px">
                  {{ item.sale ? item.sale.salesCode : '' }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </v-main>
</template>

<script>
export default {
  name: 'AgencySearchList',
  props: {
    records: Array,
    checkboxes: Array,
    subheader1: String,
    subheader2: String,
    subheader3: String,
    placeholder: String,
    onSearch: Function,
  },
  data: () => ({
    // 全選択が指定されているかどうか
    isSelectedAll: false,
  }),
  watch: {
    records: {
      handler() {
        this.$set(this, 'isSelectedAll', false);
      },
      deep: true,
    },
  },
  methods: {
    // 全選択チェックボックスの変更時
    onChangeSelectAll() {
      this.checkboxes.forEach(checkbox => {
        checkbox.selected = this.isSelectedAll;
      });
    },
  },
};
</script>
<style scoped>
.v-list {
  height: 360px; /* or any height you want */
  overflow-y: auto;
}
</style>
