var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "px-0 py-0" },
    [
      _vm.checkboxes
        ? _c(
            "v-list-item",
            { staticStyle: { height: "16px" } },
            [
              _c(
                "v-list-item-action",
                [
                  _c("v-checkbox", {
                    on: {
                      change: function($event) {
                        return _vm.onChangeSelectAll()
                      }
                    },
                    model: {
                      value: _vm.isSelectedAll,
                      callback: function($$v) {
                        _vm.isSelectedAll = $$v
                      },
                      expression: "isSelectedAll"
                    }
                  })
                ],
                1
              ),
              _c("v-list-item-content", [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("checkbox.selectAll")) + "\n    "
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-list-item",
        { staticClass: "my-0 py-0" },
        [
          _vm.checkboxes
            ? _c("v-list-item-action", { staticClass: "my-0 py-0" })
            : _vm._e(),
          _c(
            "v-list-item-content",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-row",
                { staticClass: "my-0 py-0", staticStyle: { height: "36" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "my-0 py-0", attrs: { cols: "3" } },
                    [
                      _c("p", { staticClass: "mb-0 mt-2 py-0" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.subheader1) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _vm.subheader2
                    ? _c(
                        "v-col",
                        { staticClass: "my-0 py-0", attrs: { cols: "6" } },
                        [
                          _c("p", { staticClass: "mb-0 mt-2 py-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.subheader2) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.subheader3
                    ? _c(
                        "v-col",
                        { staticClass: "my-0 py-0", attrs: { cols: "3" } },
                        [
                          _c("p", { staticClass: "mb-0 mt-2 py-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.subheader3) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-virtual-scroll", {
        attrs: { items: _vm.records, height: "360px", "item-height": "48px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-divider"),
                _c(
                  "v-list-item",
                  {
                    key: item.id,
                    staticStyle: { height: "48px" },
                    attrs: { dense: "" }
                  },
                  [
                    _vm.checkboxes
                      ? _c(
                          "v-list-item-action",
                          [
                            _c("v-checkbox", {
                              attrs: { dense: "", "hide-details": "" },
                              model: {
                                value: _vm.checkboxes.find(function(checkbox) {
                                  return checkbox.id === item.id
                                }).selected,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.checkboxes.find(function(checkbox) {
                                      return checkbox.id === item.id
                                    }),
                                    "selected",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              checkboxes.find(checkbox => checkbox.id === item.id).selected\n            "
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-row",
                          { staticStyle: { height: "48px" } },
                          [
                            _c("v-col", { attrs: { cols: "3" } }, [
                              _c(
                                "p",
                                { staticStyle: { "font-size": "14px" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.agencyCode) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]),
                            _vm.subheader2
                              ? _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.agencyName) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.subheader3
                              ? _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.sale ? item.sale.salesCode : ""
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }