<template>
  <v-main class="px-0 py-0">
    <v-row>
      <v-col class="pb-0">
        <p class="pl-5 mt-3 mb-0 font-weight-black" style="font-size:20px;">
          {{ $t('label.agencyConnectionList') }}
        </p>
      </v-col>
      <v-col class="pb-0">
        <v-btn
          class="font-weight-black float-right"
          align="center"
          width="70vw"
          max-width="250px"
          color="next"
          style="font-size:20px"
          dark
          slot="activator"
          @click="onClickRegisterAgencyConnectionButton()"
          >{{ $t('button.agencyConnectionRegister') }}</v-btn
        >
      </v-col>
      <v-col class="pb-0">
        <v-btn
          class="font-weight-black float-right"
          align="center"
          width="70vw"
          max-width="250px"
          color="next"
          style="font-size:20px"
          dark
          slot="activator"
          @click="onClickDeleteAgencyConnectionButton()"
          >{{ $t('button.agencyConnectionDelete') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-actions>
            <AgencySearchList
              :records="agencyRecords"
              :checkboxes="agencyCheckboxes"
              :subheader1="$t('label.subheader.agencyCode')"
              :subheader2="$t('label.subheader.agencyName')"
              :subheader3="$t('label.subheader.salesCode')"
            >
            </AgencySearchList>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <AgencyConnectionSearchDialog
      @onSuccess="onSuccessSearchAgency"
      ref="agencyConnectionSearchDialog"
    ></AgencyConnectionSearchDialog>
    <AgencyConnectionUpdateConfirmDialog
      @onSuccess="onSuccessRegisterAgencyConnection"
      :isRegister="true"
      ref="agencyConnectionRegisterConfirmDialog"
    ></AgencyConnectionUpdateConfirmDialog>
    <AgencyConnectionUpdateConfirmDialog
      @onSuccess="onSuccessDeleteAgencyConnection"
      :isRegister="false"
      ref="agencyConnectionDeleteConfirmDialog"
    ></AgencyConnectionUpdateConfirmDialog>
    <CompletedDialog ref="completedDialog"></CompletedDialog>
    <ErrorDialog ref="errorDialog" />
  </v-main>
</template>

<script>
import { putAgencies, getAgencyIdList } from '@/apis/agency/agencies';
import AgencyConnectionSearchDialog from '@/components/organisms/agency/AgencyConnectionSearchDialog';
import AgencyConnectionUpdateConfirmDialog from '@/components/organisms/agency/AgencyConnectionUpdateConfirmDialog';
import AgencySearchList from '@/components/organisms/agency/AgencySearchList';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  name: 'SalesConnection',
  props: {
    salesId: Number,
  },
  components: {
    AgencyConnectionSearchDialog,
    AgencyConnectionUpdateConfirmDialog,
    AgencySearchList,
    CompletedDialog,
    ErrorDialog,
  },
  data: () => ({
    // 代理店データ
    agencyRecords: [],

    // 代理店選択データ
    agencyCheckboxes: [],
  }),
  async mounted() {
    await this.fetchgencies();
  },
  methods: {
    // 代理店一覧の取得
    async fetchgencies() {
      // 代理店一覧の取得
      const agencies = await getAgencyIdList({
        salesId: this.salesId,
      }).catch(() => {});

      // 代理店一覧が取得できなかった場合、以降の処理を中止する
      if (!agencies) return;

      // 代理店選択状態の指定
      const agencyCheckboxes = agencies.map(agency => ({
        ...agency,
        selected: false,
      }));

      // 代理店一覧表示の更新
      this.$set(this, 'agencyCheckboxes', agencyCheckboxes);
      this.$set(this, 'agencyRecords', agencies);
    },

    // 代理店一覧の更新
    async updateAgencies(condition) {
      // 代理店一覧の更新
      const result = await putAgencies(condition).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyUpdateError'),
          this.$t('error.agencyUpdateBadRequest')
        );
      });

      // 代理店一覧の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      this.fetchgencies();

      this.$refs.agencyConnectionSearchDialog.close();
      this.$refs.agencyConnectionRegisterConfirmDialog.close();
      this.$refs.agencyConnectionDeleteConfirmDialog.close();

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 代理店紐付け登録ボタン押下時
    async onClickRegisterAgencyConnectionButton() {
      this.$refs.agencyConnectionSearchDialog.open();
    },

    // 代理店紐付け削除ボタン押下時
    async onClickDeleteAgencyConnectionButton() {
      const agencies = this.agencyCheckboxes.filter(agency => agency.selected);
      // 代理店が選択されていない場合
      if (agencies.length < 1) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyConnectionUpdateConfirm'),
          this.$t('error.invalidAgencySelect')
        );
        return;
      }

      this.$refs.agencyConnectionDeleteConfirmDialog.set(
        this.salesId,
        agencies
      );
      this.$refs.agencyConnectionDeleteConfirmDialog.open();
    },

    // 代理店の検索時
    async onSearchAgency(keyword) {
      await this.fetchAgencies({
        ...(keyword && {
          salesCode: keyword,
        }),
      });
    },

    // 代理店検索の成功時
    onSuccessSearchAgency(agencies) {
      this.$refs.agencyConnectionRegisterConfirmDialog.set(
        this.salesId,
        agencies
      );
      this.$refs.agencyConnectionRegisterConfirmDialog.open();
    },

    // 代理店紐付け登録の成功時
    async onSuccessRegisterAgencyConnection(agencyIds) {
      await this.updateAgencies({
        salesId: this.salesId,
        agencyId: agencyIds,
        isDelete: false,
      });
    },

    // 代理店紐付け解除の成功時
    async onSuccessDeleteAgencyConnection(agencyIds) {
      await this.updateAgencies({
        salesId: this.salesId,
        agencyId: agencyIds,
        isDelete: true,
      });
    },
  },
};
</script>
