<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout v-show="!isLoading && !isAvailable" column wrap>
      <p class="mt-2">{{ $t('description.salesNotFound') }}</p>
      <v-btn
        class="font-weight-black float-right mr-4"
        width="70vw"
        max-width="350px"
        color="next"
        style="font-size:20px"
        dark
        @click="onClickBackHomeButton()"
        >{{ $t('button.backHome') }}</v-btn
      >
    </v-layout>

    <v-layout v-show="!isLoading && isAvailable" column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.salesDetail') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.salesDetailTop.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <p
                class="pl-5 mt-3 mb-0 font-weight-black"
                style="font-size:20px;"
              >
                {{ $t('label.salesInfo') }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-btn
                class="font-weight-black float-right"
                align="center"
                width="70vw"
                max-width="250px"
                color="next"
                style="font-size:20px"
                :dark="isSalesEditButtonEnabled"
                :disabled="!isSalesEditButtonEnabled"
                slot="activator"
                @click="onClickEditSalesButton()"
                >{{ $t('button.salesEdit') }}</v-btn
              >
            </v-col>
            <v-col class="pb-0">
              <v-btn
                class="font-weight-black float-right"
                align="center"
                width="70vw"
                max-width="250px"
                color="next"
                style="font-size:20px"
                :dark="isSalesDeleteButtonEnabled"
                :disabled="!isSalesDeleteButtonEnabled"
                @click="onClickSalesDeleteButton()"
                >{{ $t('button.salesDelete') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-simple-table
            class="elevation-1 v_base_table_fill_width v_base_table_with_border"
            style="width: 100%;"
          >
            <tbody>
              <tr v-for="salesRecord in salesRecords" :key="salesRecord.key">
                <td
                  class="text-left .v_base_table_with_border base_td_category_text"
                >
                  {{ $t(`header.salesListTable.${salesRecord.key}`) }}
                </td>
                <td>{{ salesRecord.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <SalesConnection v-if="!isAgency() && sales.id" :salesId="sales.id" />
      <!-- 営業店編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditInsuranceDialog"
        :title="$t('title.agency.salesEdit')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditInsurance"
        :onClickPositiveButton="updateInsurance"
        :targets="editInsuranceTargets"
      />
      <!-- 営業店削除ダイアログ -->
      <DeleteDialog
        :title="$t('title.agency.salesDelete')"
        @onSuccess="onSuccessDeleteInsurance"
        ref="salesDeleteDialog"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { ErrorCode, MaxLength } from '@/lib/const';
import SalesConnection from '@/components/organisms/agency/SalesConnection';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import DeleteDialog from '@/components/organisms/agency/DeleteDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { Role } from '@/lib/role';
import { getSales, putSales, deleteSales } from '@/apis/agency/sales';

export default {
  name: 'SalesDetail',
  components: {
    SalesConnection,
    ErrorDialog,
    FormsDialog,
    DeleteDialog,
    CompletedDialog,
  },
  data: vm => ({
    // 営業店編集データ (デフォルト)
    editInsuranceDefaultTargets: [
      {
        value: null,
        targetName: 'salesCode',
        type: 'text',
        label: vm.$t('header.salesListTable.salesCode'),
        text: null,
        rule: `required|noSpace|pattern:salesCode`,
        key: 'salesCode',
      },
      {
        value: null,
        targetName: 'salesDepartmentName',
        type: 'text',
        label: vm.$t('header.salesListTable.salesDepartmentName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'salesDepartmentName',
      },
      {
        value: null,
        targetName: 'salesSectionName',
        type: 'text',
        label: vm.$t('header.salesListTable.salesSectionName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'salesSectionName',
      },
    ],

    // 営業店編集データ
    editInsuranceTargets: [],

    // 営業店編集ダイアログが表示されているかどうか
    showEditInsuranceDialog: false,

    // 営業店データ
    sales: {},

    // 部担コード
    salesCode: {},

    // 営業店情報
    salesRecords: [],

    // 営業店編集ボタンが有効であるかどうか
    isSalesEditButtonEnabled: false,

    // 営業店削除ボタンが有効であるかどうか
    isSalesDeleteButtonEnabled: false,
    // ローディング中であるかどうか
    isLoading: true,

    // 有効であるかどうか
    isAvailable: true,
  }),
  async mounted() {
    // 営業店の取得
    await this.fetchSales();
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    isAgency() {
      return Role.isAgency(this.userDetail().role);
    },

    // 営業店の取得
    async fetchSales() {
      // 営業店の取得
      const sales = await getSales(this.$route.params['sales_id']).catch(() => {
        this.$set(this, 'isAvailable', false);
      });
      this.$set(this, 'isLoading', false);

      // 営業店が取得できなかった場合、以降の処理を中止する
      if (!sales) return;

      // 営業店編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isSalesEditButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'salesEdit', {
          officeId: sales.id,
        })
      );
      // 営業店削除ボタンの有効状態をセットする
      this.$set(
        this,
        'isSalesDeleteButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'salesDelete', {
          officeId: sales.id,
        })
      );

      // 営業店データをセットする
      this.$set(this, 'sales', sales);

      // 営業店情報表示の更新
      this.$set(this, 'salesRecords', this.mapSales(sales));
    },

    // 営業店データのマッピング
    mapSales(sales) {
      return [
        { key: 'salesCode', value: sales.salesCode },
        { key: 'salesDepartmentName', value: sales.salesDepartmentName },
        { key: 'salesSectionName', value: sales.salesSectionName },
      ];
    },

    // 営業店編集キャンセル
    cancelEditInsurance() {
      this.$set(this, 'showEditInsuranceDialog', false);
    },

    // 営業店更新
    async updateInsurance(item) {
      const sales = {
        salesCode:
          this.salesCode !== item.salesCode ? item.salesCode : undefined, // 値に変更がない場合、送信しない
        salesDepartmentName: item.salesDepartmentName,
        salesSectionName: item.salesSectionName,
      };
      // 営業店の更新
      const result = await putSales(this.sales.id, sales).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.DuplicateSalesCode:
            message = this.$t('error.duplicateSalesCode', {salesCode:item.salesCode});
            break;
          default:
            message = this.$t('error.salesUpdateBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.salesUpdateError'),
          message
        );
      });

      // 営業店が更新できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchSales();

      this.$set(this, 'showEditInsuranceDialog', false);
      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 営業店情報編集ボタン押下時
    async onClickEditSalesButton() {
      this.$set(this, 'salesCode', this.sales.salesCode);

      const sales = {
        salesCode: this.sales.salesCode,
        salesDepartmentName: this.sales.salesDepartmentName,
        salesSectionName: this.sales.salesSectionName,
      };
      const editInsuranceTargets = JSON.parse(
        JSON.stringify(this.editInsuranceDefaultTargets)
      )
        .filter(target => Role.canEdit(this.userDetail().role, target.key))
        .map(target => {
          target.value = sales[target.targetName];
          return target;
        });

      this.$set(this, 'editInsuranceTargets', editInsuranceTargets);
      this.$set(this, 'showEditInsuranceDialog', true);
    },

    // 営業店削除ボタン押下時
    async onClickSalesDeleteButton() {
      this.$refs.salesDeleteDialog.open();
    },

    // 営業店削除成功時
    async onSuccessDeleteInsurance() {
      // 営業店の削除
      const result = await deleteSales(this.sales.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.salesDeleteError'),
          this.$t('error.salesDeleteBadRequest')
        );
      });

      // 営業店が削除できなかった場合、以降の処理を中止する
      if (!result) return;

      this.$refs.salesDeleteDialog.close();

      // 営業店一覧画面に遷移する
      this.$router.push('/sales_list');
    },

    // ホーム画面に戻るボタン押下時
    onClickBackHomeButton() {
      // ホーム画面に遷移する
      this.$router.push('/Home');
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
.base_td_category_text {
  width: 230px;
  background: #cccccc;
}
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
